<template>
  <div
    class="bg-no-repeat h-onboarding-bg w-onboarding-bg mx-auto pt-15"
    :style="{ 'background-image': url(bgImage) }"
  >
    <div class="w-onboarding-stepone mx-auto">
      <img src="@/assets/img/logo.svg" width="130" class="mb-2" />
    </div>
    <div class="bg-white w-onboarding-stepone py-9 mx-auto px-55">
      <component :is="userRole" />
      <div class="flex justify-center">
        <Button
          text="Continue"
          type="primary"
          size="large"
          class="w-a-full"
          :onClick="onClick"
          data-testid="continue-button"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Button from "../../../components/Button/Button";
import bgImage from "../../../assets/img/onboarding/welcome-small.png";
import Manager from "./Manager";
export default {
  name: "FirstStep",
  components: { Button, Manager },
  data: () => ({ bgImage }),
  props: { role: { type: String, default: "employee" } },
  computed: {
    userRole() {
      switch (this.role) {
        case "manager":
          return Manager;
        default:
          return Manager;
      }
    },
  },
  methods: {
    ...mapActions({
      setStep: "onboarding/setStep",
    }),
    onClick() {
      this.setStep({ step: 1 });
    },
    url(value) {
      return "url(" + value + ")";
    },
  },
};
</script>

<style></style>
