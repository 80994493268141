<template>
  <div>
    <p class="font-bold text-l text-grey-dark-2 mb-2">Welcome!</p>
    <p class="text-mdh text-grey-dark-1 mb-7">
      Welcome to the Advance platform powered by ISM®. Assessments delivered
      through the platform identify strengths and skill gaps to assist in
      creating individual development plans tied to organizational objectives.
    </p>
  </div>
</template>

<script>
export default {
  name: "Manager",
};
</script>
