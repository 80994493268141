<template>
  <div class="mt-7">
    <div v-if="step === 0">
      <FirstStep :role="role" />
    </div>
    <div v-else-if="step === 1">
      <SecondStep :role="role" />
    </div>
    <div v-else-if="step === 2 && jwt">
      <InviteUsers :organization="jwt.organizationName" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import jwt_decode from 'jwt-decode';
import FirstStep from './components/FirstStep';
import SecondStep from './components/SecondStep.vue';
import InviteUsers from './components/InviteUsers.vue';

export default {
  name: 'Onboarding',
  data: () => ({ role: null, jwt: null }),
  components: { FirstStep, SecondStep, InviteUsers },
  computed: {
    ...mapState({
      step: (state) => state.onboarding.step,
      onboardingStatus: (state) => state.auth.onboardingStatus,
    }),
  },
  async created() {
    await setTimeout(() => {
      this.logout({ doRedirect: false });
    }, 0);
  },
  async mounted() {
    const decodedToken = atob(unescape(this.$route.query.token));
    const jwt = jwt_decode(decodedToken);
    this.jwt = jwt;
    await this.getOnboardingStatus({ email: this.jwt.email });
    if (!this.onboardingStatus) this.$router.push('/login');
    if (typeof jwt.roles === 'string') {
      this.role = jwt.roles.toLowerCase();
    } else {
      this.role = jwt.roles[0].toLowerCase();
    }
    this.setStep({ step: 0 });
  },
  methods: {
    ...mapActions({
      setStep: 'onboarding/setStep',
      logout: 'auth/logout',
      getOnboardingStatus: 'auth/getOnboardingStatus',
    }),
  },
  beforeRouteEnter(to, _, next) {
    const decodedToken = atob(unescape(to.query.token));
    let jwt = jwt_decode(decodedToken);
    if (jwt.code && jwt.email) {
      next();
    } else {
      next('/login');
    }
  },
};
</script>
