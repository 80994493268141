<template>
    <div class="h-onboarding-bg w-onboarding-bg mx-auto pt-5">
        <div class="w-onboarding-stepone mx-auto">
            <img src="@/assets/img/logo.svg" width="130" class="mb-2" />
        </div>
        <div class="bg-white w-onboarding-stepone py-6 mx-auto px-55">
            <p class="font-bold text-grey-dark-2 text-l mb-1.5">Company Setup for {{ organization }}</p>
            <p class="text-mdh text-grey-dark-1 mb-6">
                It is now time to identify those company employees who will participate in the assessment process with
                you. Please use the attached template to identify the necessary information for each person. You may
                upload a complete list of all company employees or a subset of employees to begin. All information will
                be retained for future use and you may add to this list at any time.
            </p>
            <div class="flex flex-col justify-center items-center">
                <div class="w-250 mb-2">
                    <Button
                        text="Download Template File (.CSV)"
                        type="secondary"
                        class="w-full"
                        @click.native="downloadTemplate"
                        data-testid="download-button"
                    />
                </div>
                <div class="w-250">
                    <Button
                        text="Upload Employee File (.CSV)"
                        type="primary"
                        class="w-full"
                        :onClick="upload"
                        data-testid="upload-button"
                    />
                </div>
                <div class="mt-2">
                    <Button text="Skip" type="tertiary" class="w-full" :onClick="skip" data-testid="skip-button" />
                </div>
            </div>
        </div>
        <input
            type="file"
            ref="files"
            @change="handleFileUpload"
            class="hidden"
            :key="inputKey"
            data-testid="files-field"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import _trim from 'lodash/trim';

export default {
    name: 'InviteUsers',
    data: () => ({
        users: [],
        inputKey: 0,
    }),
    props: {
        organization: {
            type: String,
            default: '',
        },
    },
    methods: {
        ...mapActions({
            downloadTemplate: 'utils/downloadTemplate',
            setUserPreviewList: 'assessmentSetup/setUserPreviewList',
            openModal: 'modals/openModal',
        }),
        skip() {
            this.$router.push('/');
        },
        open() {
            this.openModal({ modal: 'usersPreview' });
        },
        handleFileUpload() {
            this.inputKey += 1;
            this.$papa.parse(this.$refs.files.files[0], {
                header: true,
                encoding: "ISO-8859-1",
                complete: (results) => {
                    let errors = 0;
                    if (results.meta['fields'].indexOf('First Name') < 0) errors += 1;
                    if (results.meta['fields'].indexOf('Last Name') < 0) errors += 1;
                    if (results.meta['fields'].indexOf('Job Title') < 0) errors += 1;
                    if (results.meta['fields'].indexOf('Email') < 0) errors += 1;
                    if (errors === 0) {
                        this.users = results.data
                            .map((el) => ({
                                firstName: _trim(el['First Name']),
                                lastName: _trim(el['Last Name']),
                                email: _trim(el['Email']),
                                jobTitle: _trim(el['Job Title']),
                            }))
                            .filter((el) => el.firstName !== '' && el.lastName !== '' && el.email !== '');
                        this.setUserPreviewList({ users: this.users });
                        this.openModal({ modal: 'usersPreview' });
                    } else {
                        this.showError({
                            messageHeader: 'Upload Error',
                            message:
                                'Please check your file to make sure that it uses the same format as the template provided and upload again.',
                        });
                    }
                },
                error: async (err) => {
                    this.showError({
                        messageHeader: 'Upload Error',
                        message:
                            'Please check your file to make sure that it uses the same format as the template provided and upload again.',
                    });
                },
            });
        },
        upload() {
            this.$refs.files.click();
        },
    },
};
</script>

<style></style>
